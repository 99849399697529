const siteName = "ONECYCLE";
const siteSlogan = "Rhythm Indoor Cycling Boutique";
const siteDescription =
  "OneCycle offers 45-minute rhythm indoor cycling classes where you ride to the beat of the music, immerse in the nightclub lighting, and get one heck of a workout!";
const TITLE_SEPARATOR = " -";

const fullTitle = `${siteName}${TITLE_SEPARATOR} ${siteSlogan}`;

export const defaultSeo = {
  defaultTitle: fullTitle,
  description: siteDescription,
  canonical:
    process.env.NEXT_PUBLIC_WEBSITE_SITE_URL ||
    process.env.NEXT_PUBLIC_VERCEL_URL ||
    "NO_PUBLIC_WEBSITE_SITE_URL",
  additionalMetaTags: [
    {
      property: "build:env",
      content:
        process.env.NEXT_PUBLIC_ENVIRONMENT ||
        process.env.NEXT_PUBLIC_VERCEL_ENV ||
        "NO_ENV",
    },
    {
      property: "build:version",
      content: process.env.NEXT_PUBLIC_VERCEL_GIT_REPO_ID || "NO_GIT_REPO_ID",
    },
    {
      property: "build:version:commit",
      content: process.env.NEXT_PUBLIC_COMMIT_SHA || "NO_PUBLIC_COMMIT_SHA",
    },
    {
      property: "build:commit:auth",
      content:
        process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_AUTHOR_NAME ||
        "NO_AUTHOR_NAME",
    },
    // Generate favicons in https://realfavicongenerator.net
    {
      name: "theme-color",
      content: "#ffffff",
    },
    {
      name: "msapplication-TileColor",
      content: "#ffffff",
    },
  ],
  additionalLinkTags: [
    // Generate favicons in https://realfavicongenerator.net
    {
      rel: "apple-touch-icon",
      type: "image/png",
      href: "/favicons/apple-touch-icon.png",
      sizes: "180x180",
    },
    {
      rel: "icon",
      type: "image/png",
      href: "/favicons/favicon-32x32.png",
      sizes: "32x32",
    },
    {
      rel: "icon",
      type: "image/png",
      href: "/favicons/favicon-16x16.png",
      sizes: "16x16",
    },
    {
      rel: "manifest",
      href: "/favicons/site.webmanifest",
    },
    {
      rel: "mask-icon",
      href: "/favicons/safari-pinned-tab.svg",
      color: "#000000",
    },
  ],
  openGraph: {
    title: fullTitle,
    description: siteDescription,
    url:
      process.env.NEXT_PUBLIC_WEBSITE_SITE_URL ||
      process.env.NEXT_PUBLIC_VERCEL_URL ||
      "NO_PUBLIC_WEBSITE_SITE_URL",
    images: [
      {
        url: `${
          process.env.NEXT_PUBLIC_WEBSITE_SITE_URL ||
          process.env.NEXT_PUBLIC_VERCEL_URL
        }/images/thumbnail.png`,
        alt: fullTitle,
      },
    ],
  },
  twitter: {
    cardType: "summary_large_image",
  },
  titleTemplate: `${siteName}${TITLE_SEPARATOR} %s`,
};

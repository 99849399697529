import { defaultSeo, pageTransitionSpeed } from "@/config";
import { GtmScript } from "@/utils/analytics";
import { ThemeProvider } from "@hybrbase/system";
import { Theme } from "@boilerplate/themes";
import * as Sentry from "@sentry/nextjs";

import {
  AnimatePresence,
  domAnimation,
  isBrowser,
  LazyMotion,
} from "framer-motion";
import { NextPage } from "next";
import { DefaultSeo } from "next-seo";
import type { AppProps as NextAppProps } from "next/app";
import { Router } from "next/router";
import { ReactElement, ReactNode, useEffect, useState } from "react";
import { ReactQueryDevtools } from "react-query/devtools";

import { Hydrate, QueryClient, QueryClientProvider } from "react-query";

// // Make sure to return the specific export containing the feature bundle.
// const loadFeatures = () =>
//   import("../lib/framer-motion/motion-features").then((res) => res.default);

if (process.env.NODE_ENV === "production") {
  // Need to ensure that the latest tailwind.config is always in sync on deployment and production
  require("../../public/styles/tailwind.css");
} else {
  require("@/styles/tailwind.css");
}

type AppProps = {
  isMobile: boolean;
  Component: NextPage & {
    theme?: string;
    getLayout?: (page: ReactElement) => ReactNode;
  };
} & NextAppProps;

function MyApp({ Component, pageProps, router }: AppProps) {
  const [isPageTransition, setPageTransition] = useState<boolean>(false);

  // Use the layout defined at the page level
  const getLayout = Component.getLayout || ((page) => page);

  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            keepPreviousData: true,
            refetchOnMount: false,
            refetchOnWindowFocus: false,
          },
        },
        logger: {
          log: (message) => {
            Sentry.captureMessage(message);
          },
          warn: (message) => {
            Sentry.captureMessage(message);
          },
          error: (error) => {
            Sentry.captureException(error);
          },
        },
      })
  );

  // Setup page transition loading states
  useEffect(() => {
    Router.events.on("routeChangeStart", (_, { shallow }) => {
      // Bail if we're just changing URL parameters
      if (shallow) return;

      // Otherwise, start loading
      setPageTransition(true);
    });

    Router.events.on("routeChangeComplete", () => {
      setTimeout(() => setPageTransition(false), pageTransitionSpeed);
    });

    Router.events.on("routeChangeError", () => {
      setPageTransition(false);
    });
  }, [setPageTransition]);

  // Setup page transition loading states
  useEffect(() => {
    if (isBrowser) {
      document.documentElement.style.scrollBehavior = isPageTransition
        ? "auto"
        : "smooth";
    }
  }, [isPageTransition]);

  return (
    <QueryClientProvider client={queryClient}>
      <Hydrate state={pageProps.dehydratedState}>
        <GtmScript />
        <DefaultSeo {...defaultSeo} />

        <ThemeProvider
          themes={[Theme.Default, Theme.Dark]}
          defaultTheme={Theme.Default}
          isPageTransition={isPageTransition}
        >
          <AnimatePresence
            exitBeforeEnter
            onExitComplete={() => console.log("onExitComplete")}
          >
            <LazyMotion features={domAnimation} strict>
              {getLayout(<Component key={router.route} {...pageProps} />)}
            </LazyMotion>
          </AnimatePresence>
        </ThemeProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </Hydrate>
    </QueryClientProvider>
  );
}

export default MyApp;
